import { Component, Vue, Model } from 'vue-property-decorator';
import { Report as ReportModel } from '@/models/Report';
import HistoricalDamagePanel from '@/components/historical-damage-panel/HistoricalDamagePanel.vue';

@Component<HistorischeSchades>({
  components: {
    HistoricalDamagePanel,
  },
})
export default class HistorischeSchades extends Vue {
  public $pageTitle = 'Historische Schades';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  protected isLoading = false;

  protected mounted() {

  }
}
